<template>
  <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sign__content">
                        <form action="signup.php" class="sign__form">
                            <a href="index.php" class="sign__logo">
                                <img src="assets/img/logoSvg.svg" alt="">
                            </a>
                            <template v-for="sign in price" :key="sign.id">
                            <div class="sign__group">
                                <router-link @click="getMonthly(sign)" :to="{ name: 'Signup'}" class="sign__btn" type="submit">شهري 30 SDG</router-link>
                            </div>
                            <div class="sign__group">
                                <router-link @click="getWeekly(sign)" :to="{ name: 'Signup'}" class="sign__btn" type="submit">اسبوعي 15 SDG</router-link>
                            </div>
                            <div class="sign__group">
                                <router-link @click="getDaily(sign)" :to="{ name: 'Signup'}" class="sign__btn" type="submit">يومي 5 SDG</router-link>
                                <span class="sign__delimiter">قيمة الاشتراك شاملة الضريبة و تجدد تلقائيا </span>
                            </div>
                            </template>
                        </form>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import { useCookie } from 'vue-cookie-next'
import axios from "axios";
import config from '@/Api/config';

export default {
  name: 'Subscribes',  
  async setup() {
      const cookie = useCookie()
      const price = await axios.get('GetCategoriesPrice.php?CAID='+cookie.getCookie("cat_id"),{
           headers: {
               'Authorization': config.authHeader,
            },
      })
      .then((response) => {return response.data.Prices;});
      return {
         price,      
    };
  },
  methods: {
    getMonthly: function (sign) {
        this.$cookie.setCookie('product_code', sign.monthly, {
            expire: 60 * 5,
        })
        this.$cookie.setCookie('SUBID', 1, {
            expire: 60 * 5,
        })
    },
    getWeekly: function (sign) {
        this.$cookie.setCookie('product_code', sign.weekly, {
            expire: 60 * 5,
        })
        this.$cookie.setCookie('SUBID', 2, {
            expire: 60 * 5,
        })
    },
    getDaily: function (sign) {
        this.$cookie.setCookie('product_code', sign.daily, {
            expire: 60 * 5,
        })
        this.$cookie.setCookie('SUBID', 3, {
            expire: 60 * 5,
        });
    },
  },
}
</script>

<style>

</style>